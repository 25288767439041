<template>
  <section style="display: visible">
    <div
      :style="getElementStyle"
      :height="data.height"
      :width="data.width"
      :class="{
        'rounded-div': data.styles.selectedShape === 'circle',
        'rectangle-div': data.styles.selectedShape === 'rectangle',
      }"
    >
      <div class="icon-container">
        <div v-html="iconContent"></div>
      </div>
      <div>
        <span v-if="isActive" class="setting-icon">
          <i class="el-icon-s-tools" @click="openSettings"></i>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { fetchIconByName } from "@/repo/iconsRepo";
import FieldStylesHelper from "@/mixins/FieldStylesHelper";
export default {
  async mounted() {
    this.loading = true;
    await this.fetchIcon();
  },
  props: ["field", "data", "isActive"],
  mixins: [FieldStylesHelper],
  data() {
    return {
      loading: false,
      iconContent: `<svg width="24" height="23" viewBox="0 0 24 23" class="icon" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 11.9724C1 9.04452 2.13891 6.29195 4.20733 4.22012L4.20741 4.22008L4.21376 4.21372C6.28626 2.14123 9.04148 1 11.9724 1C14.9034 1 17.6586 2.14127 19.7311 4.21372C21.7962 6.27881 22.9367 9.0218 22.9448 11.941V11.9725V11.9726C22.9448 14.3687 22.1861 16.645 20.7506 18.5561L20.7947 18.5893L20.7506 18.5561C20.639 18.7048 20.464 18.7922 20.2781 18.7922H3.66686C3.48101 18.7922 3.30595 18.7048 3.19434 18.5562L3.15025 18.5893L3.19433 18.5562C1.75877 16.6451 1.00004 14.3688 1 11.9726V11.9725V11.9725V11.9724ZM19.9489 17.6102H19.9776L19.9941 17.5868C21.028 16.1141 21.6251 14.4144 21.7414 12.6222L21.7452 12.5635L21.6864 12.5635L20.9696 12.5634C20.6432 12.5634 20.3786 12.2988 20.3786 11.9724C20.3787 11.646 20.6433 11.3814 20.9696 11.3814L21.6857 11.3815H21.7446L21.7407 11.3227C21.5951 9.1113 20.7123 7.09829 19.336 5.52774L19.2972 5.48346L19.2556 5.5251L18.7522 6.02835C18.6369 6.14374 18.4857 6.20144 18.3344 6.20144C18.1831 6.20144 18.0319 6.14374 17.9165 6.02831C17.6857 5.79752 17.6857 5.42329 17.9165 5.19251L18.4198 4.68933L18.4615 4.64769L18.4172 4.60887C16.8466 3.23256 14.8336 2.34976 12.6222 2.2041L12.5634 2.20024V2.25912V2.97523C12.5634 3.30162 12.2988 3.56623 11.9724 3.56623C11.6461 3.56623 11.3814 3.30162 11.3814 2.97523V2.25912V2.20024L11.3226 2.2041C9.11126 2.34976 7.09824 3.23256 5.5277 4.60883L5.48341 4.64764L5.52505 4.68929L6.0283 5.1925C6.25913 5.42333 6.25913 5.79752 6.02835 6.02831C5.91291 6.14374 5.76175 6.20144 5.61045 6.20144C5.45906 6.20144 5.30796 6.14379 5.19255 6.02835L5.15356 6.06734L5.19254 6.02835L4.68924 5.5251L4.6476 5.48346L4.60878 5.52774C3.23251 7.09829 2.34976 9.1113 2.2041 11.3227L2.20024 11.3815H2.25913L2.97514 11.3814C3.30154 11.3814 3.56619 11.646 3.56619 11.9724C3.56623 12.2988 3.30162 12.5634 2.97523 12.5634L2.25847 12.5635L2.19965 12.5635L2.20346 12.6222C2.31973 14.4145 2.91686 16.1142 3.95088 17.5868L3.96735 17.6102H3.99601H19.9489Z" class="primary"
        stroke="white" stroke-width="0.4"/>
        <path d="M7.81983 21.8578H16.125C16.399 21.8578 16.6211 22.0799 16.6211 22.3539C16.6211 22.6279 16.399 22.8501 16.125 22.8501H7.81983C7.54587 22.8501 7.32371 22.6279 7.32371 22.3539C7.32371 22.0799 7.54587 21.8578 7.81983 21.8578Z" class="primary"
        stroke="white" stroke-width="0.3"/>
        <path d="M15.9168 8.02787L15.9168 8.02791C16.1106 8.22169 16.1106 8.53579 15.9168 8.72957L14.1928 10.4536L14.1094 10.537L14.1707 10.6377C14.408 11.0271 14.5448 11.4838 14.5448 11.9723C14.5448 13.3906 13.3907 14.5448 11.9724 14.5448C10.5541 14.5448 9.39997 13.3907 9.39997 11.9723C9.39997 10.554 10.5541 9.39991 11.9724 9.39991C12.4609 9.39991 12.9177 9.53674 13.307 9.77398L13.4077 9.83536L13.4911 9.75196L15.2152 8.02791L15.2152 8.0279C15.409 7.83408 15.7232 7.83412 15.9168 8.02787ZM10.3923 11.9723C10.3923 12.8437 11.101 13.5525 11.9725 13.5525C12.8439 13.5525 13.5526 12.8438 13.5526 11.9723C13.5526 11.1009 12.8439 10.3922 11.9725 10.3922C11.1011 10.3922 10.3923 11.1009 10.3923 11.9723Z" class="primary"
        stroke="white" stroke-width="0.3"/>
        </svg>`,
    };
  },
  computed: {
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    getStyle() {
      let inputStyle = "";
      if (this.data.styles) {
        inputStyle += this.data.styles.input_background
          ? `background-color: ${this.data.styles.input_background};`
          : "";
      }
      return inputStyle;
    },
  },
  methods: {
    openSettings() {
      this.$emit("settings");
    },
    async fetchIcon() {
      try {
        let name = this.data.label.replace(".svg", "");
        let icon = await fetchIconByName(name);
        if (icon?.content) {
          this.iconContent = icon.content;
        }
        this.loading = false;
      } catch (error) {
        console.error("Error fetching icons:", error);
      }
    },
  },
  watch: {
    "data.label": {
      async handler() {
        this.loading = true;
        await this.fetchIcon();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.rounded-div,
.rectangle-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-div {
  background-color: #ccc;
  border-radius: 50%;
}

.rectangle-div {
  background-color: #ccc;
  border-radius: 0;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
